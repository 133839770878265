<template lang="html">
  <div class="category" v-if="!this.loading && this.category != null">
    <div class="top">
      <div class="top_container">
        <div class="text_line">
          <div class="lined_text">
            <h1 class="text">{{ category.title }}</h1>
            <div class="long" />
          </div>
          <h2>{{ category.subtitle }}</h2>
        </div>

        <div class="contact">
          <img src="../assets/mail.svg" id="mail_img" /><a
            href="mailto:k3@kreps-grafik.de"
            id="mail_text"
            >k3@kreps-grafik.de</a
          >
          <br />
          <img src="../assets/phone.svg" id="phone_img" /><a
            href="tel:+49-160-9706340"
            id="phone_text"
            >+49 160 9706340</a
          >
        </div>
      </div>
      <img
        id="world"
        :src="`http://kreps-grafik.de${category.image.path}`"
        alt=""
      />
    </div>
    <main>
      <div class="element" v-for="project in data" :key="project._id">
        <a :href="`project/${project.url}`">
          <img :src="`http://kreps-grafik.de${project.Image.path}`" alt="" />
          <p>
            <strong>{{ project.Customer }} _ </strong
            >{{ project.Short_description }}
          </p>
        </a>
      </div>
    </main>
  </div>
</template>

<script>
import CMSService from "../CMS-Service.js";
export default {
  props: ["url"],
  data() {
    return {
      category: null,
      data: new Array(),
      loading: true,
    };
  },
  created() {
    CMSService.getCollectionEntries("Kategorie").then((data) => {
      for (var i = 0; i < data.data.entries.length; i++) {
        if (data.data.entries[i].url == this.url) {
          this.category = data.data.entries[i];
          break;
        }
      }
      if (this.category == null) {
        this.$router.push({ name: "404" });
      }
    });
    CMSService.getCollectionEntries("Projekte").then((data) => {
      for (var i = 0; i < data.data.entries.length; i++) {
        if (data.data.entries[i].Group.includes(this.url)) {
          this.data.push(data.data.entries[i]);
        }
      }
    });
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "../main.scss";

.top {
  background-color: white;
  padding: 0 33px;
  width: calc(100% - 66px);

  .top_container {
    position: relative;
    margin: auto;
    max-width: 1920px;
  }

  .long {
    border-bottom: 2px solid $k3-red;
    margin-bottom: 18px;
  }

  .text_line {
    max-width: 450px;
    margin-top: 30px;
  }

  .contact {
    z-index: 2;
    position: absolute;
    right: 0px;
    margin-top: 50px;

    #mail_img,
    #phone_img {
      width: 18px;
      filter: invert(1);
    }
    #mail_text {
      font-family: "PT Sans";
      font-size: 20px;
      font-weight: regular;
      line-height: 24px;
      text-decoration: none;
      color: $k3-black;
      margin-left: 22px;
    }

    #phone_img {
      position: absolute;
      margin-top: 27px;
    }

    #phone_text {
      writing-mode: vertical-rl;
      font-family: droid-serif, serif;
      font-size: 30px;
      font-weight: bold;
      text-decoration: none;
      color: $k3-black;
      margin-left: 22px;
      margin-top: 27px;
    }
  }

  #world {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 1920px;
    left: 50%;
    transform: translate(-50%, 4px);
    //clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
  }
}
.category {
  background: $k3-black;

  .catrgory-description {
    max-width: 455px;
    padding-left: 8%;
    margin-top: 50px;
  }
}

main {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 78px 0;
  .element {
    width: 25vw;
    min-width: 310px;
    max-width: 400px;

    margin: 19px 19px;

    img {
      width: 100%;
    }
    p {
      color: white;
    }
  }
}

//mobile
@media screen and (max-width: 720px) {
  .text_line {
    max-width: 220px !important;
    margin-top: 0px !important;
  }
  .long {
    margin-bottom: 10px !important;
  }
  .contact {
    display: none;

    #world {
      transform: translate(-50%) !important;
    }
  }
  .description-text {
    margin-top: 25px !important;

    hr {
      display: none !important;
    }
    .text {
      width: 100% !important;
    }
  }
}
</style>
